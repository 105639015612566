<!-- Компонент корзины для телефона/страницы меню на ПК -->
<template>
	<div class="mini-cart">
		<CartHeader/>
		<BlockPreloader
			class="mini-cart__preloader mini-cart__preloader-cart"
			v-if="cartLoading"
		/>
		<template v-else>
			<CartProducts
				v-if="cartProductsShow"
				class="mini-cart__scroll"
			/>
			<div
				v-else-if="isMyOrder ? !myCartDetails.count : !cartProductsCount"
				class="mini-cart__placeholder"
			>
				<img
					class="mini-cart__placeholder-img"
					src="@/assets/img/cartEmpty.svg"
					alt="Cart empty"
				/>
				<span class="mini-cart__placeholder-title">Корзина пока пуста</span>
				<span class="mini-cart__placeholder-text">Добавьте что-нибудь из нашего меню</span>
			</div>
			<div
				class="mini-cart__order"
				v-if="isMyOrder ? myCartDetails.sum : cartSumm"
			>
				<div
					class="mini-cart__order-line"
					v-if="currentBasket?.owner && cartBalls"
				>
					<span class="title points">Баллов начислится:</span>
					<div class="mini-cart__order-bonus">
						+{{ addThousandSeparator(cartBalls) }}
						<img src="@/assets/img/icons/bonus.svg"/>
					</div>
				</div>
				<div
					class="mini-cart__order-line"
					v-if="currentBasket?.collective && currentBasket?.owner && myCartDetails.sum"
				>
					<span class="subtitle">Ваш заказ</span>
					<span class="subtitle">{{ addThousandSeparator(myCartDetails.sum) }} ₽</span>
				</div>
				<div
					class="mini-cart__order-line last"
					v-if="isMyOrder ? myCartDetails.sum : cartSumm"
				>
					<span class="price">Итого</span>
					<span class="price">{{
							addThousandSeparator(isMyOrder ? myCartDetails.sum : cartSumm)
						}} ₽</span>
				</div>
				<button
					v-if="!currentBasket?.owner && !isDone && myCartDetails.count"
					@click="toggleUserFinishedPopup"
					class="mini-cart__order-button btn"
				>
					Завершить сборку
				</button>
				<span
					v-else-if="!currentBasket?.owner && isDone"
					class="mini-cart__finished"
				>
					Вы закончили сборку
				</span>
				<button
					v-else-if="currentBasket?.owner"
					class="mini-cart__order-button btn"
					@click="order"
				>
					Перейти к оформлению
				</button>
			</div>
		</template>
		<ConfirmationPopup
			:visible="showUserFinishedPopup"
			:title="'Вы уверены, что хотите завершить сборку заказа?'"
			@closePopup="toggleUserFinishedPopup"
		>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn confirmation-popup__btn--cancel"
					@click="toggleUserFinishedPopup"
				>Отмена
				</button>
				<button
					class="btn confirmation-popup__btn"
					@click="handleUserFinished"
				>Подтвердить
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {useYandexMetrika, useUserCompleteTheBuild} from "@/composables";
import BlockPreloader from "@/components/ui/BlockPreloader.vue";
import CartHeader from "@/components/cart/CartHeader.vue";
import {addThousandSeparator} from "@/helpers";

export default {
	name: "MiniCart",
	setup()
	{
		const {yandexMetrikaGoal} = useYandexMetrika();
		const {
			showUserFinishedPopup,
			toggleUserFinishedPopup,
			handleUserFinished
		} = useUserCompleteTheBuild();

		return {
			yandexMetrikaGoal,
			showUserFinishedPopup,
			toggleUserFinishedPopup,
			handleUserFinished
		};
	},
	components:
		{
			BlockPreloader,
			CartHeader,
			CartProducts: () => import("./CartProducts.vue"),
			ConfirmationPopup: () => import("@/components/popup/ConfirmationPopup.vue"),
		},
	computed:
		{
			...mapGetters({
				cartLoading: 'getCartLoading',
				cartId: 'getCartId',
				cartSumm: 'getCartSumm',
				cartBalls: 'getCartBalls',
				currentBasket: 'getCurrentBasket',
				myCartDetails: 'getMyCartDetails',
				isMyOrder: 'getIsMyOrder',
				cartProductsCount: 'getCartProductsCount',
				isDone: 'getDone',
			}),
			cartProductsShow()
			{
				const hasOwnerAndCollective = this.currentBasket?.owner && this.currentBasket?.collective;
				const isOrderCount = this.isMyOrder ? this.myCartDetails.count : this.cartProductsCount;

				return hasOwnerAndCollective || isOrderCount
			}
		},
	methods:
		{
			...mapActions({
				userFinished: 'userFinished',
			}),
			...mapMutations({
				setDone: 'setDone',
			}),
			addThousandSeparator,
			async order()
			{
				await this.yandexMetrikaGoal(45209871, `oformit`);
				this.$router.push('/cart/');
			},
		},
};
</script>

<style lang="scss">
.mini-cart__placeholder-text
{margin-bottom: 25px;}

.mini-cart__finished
{
	display: inline-block;
	width: 100%;
	text-align: center;
	color: #979797;
	font-weight: 700;
}

.mini-cart
{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 88dvh;
	border-radius: 16px;
	border: 1px solid $greyBorder;
	transition: top .15s ease;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
	padding: 24px 30px;
	background-color: #fff;
	position: sticky;
	top: 0;

	&__preloader.mini-cart__preloader-cart
	{
		background-color: white;
		height: 100%;
	}

	&__scroll
	{
		display: flex;
		flex-direction: column;

		flex: 1;
		overflow-y: auto;
		scrollbar-width: none;
		gap: 16px;

		width: calc(100% + 12px);
		padding-left: 12px;
		margin-left: -12px;
	}

	&__placeholder
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		flex: 1;

		&-img
		{margin-bottom: 16px;}

		&-title
		{
			margin-bottom: 16px;
			font-weight: bold;
			font-size: 24px;
		}

		&-text
		{
			text-align: center;
			color: $greyLightText;
		}
	}

	&__order
	{
		width: 100%;
		padding-top: 12px;
		background: #fff;
		border-top: 1px solid #E5E5E7;

		&-line
		{
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-width: 100%;
			margin-bottom: 4px;

			.title
			{
				font-family: $mainFontMediumNew;
				font-size: 16px;
				line-height: 24px;

				&.points
				{
					color: $green;
				}
			}

			.subtitle
			{
				font-family: $mainFontMediumNew;
				font-size: 16px;
				line-height: 24px;
			}

			.price
			{
				font-family: $mainFontBoldNew;
				font-size: 20px;
				line-height: 32px;
			}

			&.last
			{margin-bottom: 12px;}
		}

		&-bonus
		{
			margin-bottom: 6px;
			flex-shrink: 0;
			background: $green;
			color: #fff;
			font-size: 14px;
			font-weight: 700;
			border-radius: 4px;
			width: 52px;
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;

			img
			{
				margin-left: 3px;
				margin-top: -2px;
			}
		}

		&-button
		{
			width: 100%;
			height: 48px;
		}
	}
}
</style>
